import { PlayerActivity } from '@app/lib/types';
import { FC, Fragment } from 'react';
import { PlayerActivityListItem } from './player-activity-list-item';
import { Card, Divider } from '@mui/material';

interface Props {
  activities: PlayerActivity[];
}

export const PlayerActivityList: FC<Props> = ({ activities }) => {
  return (
    <Card variant='outlined' className='list-card'>
      {activities.map((activity, index) =>
      <Fragment key={activity.player.id}>
        <PlayerActivityListItem activity={activity} />
        {index !== activities.length - 1 &&
        <Divider className='list-divider' />
        }
      </Fragment> 
      )}
    </Card>
  );
};
