import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

import { SiteBar } from '@app/components/site-bar';
import { AccountModel, LoginForm } from '@app/data/account';
import { isSuccess } from '@app/lib/rest';

export const LoginPage: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [email, setEmail] = useState<string>();
  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const [password, setPassword] = useState<string>();
  const onPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);

    const body: LoginForm = {
      email: email,
      password: password
    };

    const result = AccountModel.validateLogin(body);
    if (result instanceof Error) {
      setLoading(false);
      setError(result.message);
      return;
    }

    const resp = await AccountModel.login(result);
    if (isSuccess(resp)) {
      if (redirect != null && redirect !== '/logout') {
        navigate(redirect, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      setLoading(false);
      setError(resp.message);
    }
  };

  return (
    <div className='login-page'>
      <SiteBar title='Login' />
      <Container maxWidth='sm'>
        <div className='login-page__content'>
          <form onSubmit={onSubmit}>
            <div className='login-page__form'>
              <div className='spacer-16'>
                <TextField label='Email' type='email' fullWidth value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onEmailChanged(e); }} />
              </div>
              <div className='spacer-16'>
                <TextField label='Password' type='password' fullWidth value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onPasswordChanged(e); }} />
              </div>
              <div className='spacer-16'>
                {error !== undefined &&
                <Alert severity='error' className='alert-full'>{error}</Alert>
                }
              </div>
              <div className='spacer-16'>
                <LoadingButton type='submit' loading={loading} variant='contained' size='large' color='primary' 
                  className='button-primary button-min-240' disableElevation>Continue</LoadingButton>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};
