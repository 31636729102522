import { AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie"

/**
 * Basic function that will return the headers needed for an authenticated request.
 */
export const getRequestConfig = (): AxiosRequestConfig => {
  const cookies = new Cookies();
  const token = cookies.get('auditor_token');

  return {
    headers: {
      'Authorization': token
    }
  };
}
