import { FC } from 'react';

import ListItemButton from '@mui/material/ListItemButton';

import { Contest } from '@app/lib/types';
import dayjs from 'dayjs';

interface Props {
  contest: Contest;
}

export const ContestListItem: FC<Props> = ({ contest }) => {
  return (
    <ListItemButton className='list-item' href={`/contest/${contest.slug}`}>
      <p className='list-item__title'>{contest.name}</p>
      <p className='list-item__subtitle'>{dayjs(contest.startTime).format('MMMM D, YYYY')}</p>
    </ListItemButton>
  )
};
