import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Account, Requests, Responses } from '../types';

import { ErrorResponse, MSG_NETWORK_ERROR, Result } from './types';

export namespace AccountApi {
  export const signup = async (body: Requests.AccountSignUp): Promise<Result<Responses.AuthenticatedAccount>> => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/account/signup`, body);
      if (res.status === 201) {
        const resp: Responses.AuthenticatedAccount = {
          account: res.data.account,
          token: res.headers.authorization,
        };

        return resp;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const login = async (body: Requests.AccountLogin): Promise<Result<Responses.AuthenticatedAccount>> => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/account/login`, body);
      if (res.status === 200) {
        const resp: Responses.AuthenticatedAccount = {
          account: res.data.account,
          token: res.headers.authorization,
        };

        return resp;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }

        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const get = async (config: AxiosRequestConfig): Promise<Result<Account>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/account`, config);
      if (res.status === 200) {
        return res.data as Account;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }

        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const update = async (body: Requests.AccountUpdate, config: AxiosRequestConfig): Promise<Result<Account>> => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/account`, body, config);
      if (res.status === 200) {
        return res.data as Account;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }

        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const updatePassword = async (body: Requests.PasswordUpdate, config: AxiosRequestConfig): Promise<Result<boolean>> => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/account/password`, body, config);
      if (res.status === 204) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }

        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const deleteAccount = async (config: AxiosRequestConfig): Promise<Result<boolean>> => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_HOST}/account`, config);
      if (res.status === 204) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }

        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };
}
