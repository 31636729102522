import { FC } from 'react';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { SiteBar } from '@app/components/site-bar';
import { AccountInfo } from './info';
import { UpdatePasswordForm } from './password';
import { LoadingView } from '@app/components/loading';
import { useAccount } from '@app/hooks/account';
import { AccountDangerZone } from './danger';

export const AccountPage: FC = () => {
  const { data: account, isLoading } = useAccount();

  return (
    <div className='account-page'>
      <SiteBar title='Account Settings' showActions={true} />
      <Container maxWidth='sm'>
        {isLoading ?
        <LoadingView message='Loading account information' />
        :
        <div className='account-page__content spacer-16 bottom-spacer-16'>
          <div className='spacer-16'>
            <Button href='/logout' variant='contained' color='muted' fullWidth disableElevation>Logout</Button>
          </div>
          <AccountInfo account={account} />
          <div className='spacer-24' />
          <UpdatePasswordForm />
          <div className='spacer-24' />
          <AccountDangerZone />
        </div>
        }
      </Container>
    </div>
  );
};
