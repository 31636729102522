import { AuditorApi, Result } from "@app/lib/rest";
import { Auditor, Requests } from "@app/lib/types";
import { getRequestConfig } from "./header";

export namespace AuditorModel {
  export const list = async (contestSlug: string): Promise<Result<Auditor[]>> => {
    return AuditorApi.list(contestSlug, getRequestConfig());
  };

  export const add = async (req: Requests.AddAuditor): Promise<Result<boolean>> => {
    return AuditorApi.add(req, getRequestConfig());
  }

  export const remove = async (req: Requests.RemoveAuditor): Promise<Result<boolean>> => {
    return AuditorApi.remove(req, getRequestConfig());
  }
}
