import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGear } from '@fortawesome/free-solid-svg-icons';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import logo from '@app/assets/trophy-logo.svg';

interface Props {
  title?: string;
  showActions?: boolean;
}

export const SiteBar: FC<Props> = ({ title, showActions }) => {
  return (
    <AppBar position='static' elevation={0} className='site-bar__app-bar'>
      <Toolbar disableGutters className='site-bar__toolbar'>
        <a className='site-bar__brand' href='/'>
          <img src={logo} alt='Contest Auditor' className='site-bar__logo' />
          <div className='site-bar__titles'>
            <p className='site-bar__title'>Contest Auditor</p>
            {title != null &&
            <p className='site-bar__subtitle'>{title}</p>
            }
          </div>
        </a>
        {showActions &&
        <div className='site-bar__actions'>
          <IconButton href='/account' size='large' className='site-bar__action'>
            <FontAwesomeIcon size='xs' fixedWidth icon={faUserGear} />
          </IconButton>
        </div>
        }
      </Toolbar>
    </AppBar>
  );
};
