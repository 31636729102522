import { FC, Fragment } from 'react';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import { FlaggedPlayerGroup, Player } from '@app/lib/types';
import { FlaggedPlayerListItem } from './flagged-player-list-item';

interface Props {
  flaggedPlayers: FlaggedPlayerGroup[];
  onSearchPlayers: (players: Player[]) => void;
}

export const FlaggedPlayersList: FC<Props> = ({ flaggedPlayers, onSearchPlayers }) => {
  return (
    <Card variant='outlined' className='list-card'>
      {flaggedPlayers.map((group, index) => 
        <Fragment key={group.player.player.id}>
          <FlaggedPlayerListItem flaggedPlayer={group} onSearchPlayers={onSearchPlayers} />
          {index !== flaggedPlayers.length - 1 && 
          <Divider className='list-divider' />
          }
        </Fragment>
      )}
      {flaggedPlayers.length === 0 &&
      <p className='empty-list'>No flagged players</p>
      }
    </Card>
  );
};
