import { FC, Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { PlayerActivity } from '@app/lib/types';
import { Collapse, Divider, ListItem } from '@mui/material';
import { WagerListItem } from './wager-list-item';

interface Props {
  activity: PlayerActivity;
}

export const PlayerActivityListItem: FC<Props> = ({ activity }) => {
  const [expanded, setExpanded] = useState(false);
  const onClickRow = () => setExpanded(!expanded);

  const overlapCount = activity.wagers.filter(w => w.overlap).length;

  return (
    <ListItem className='list-item-fullbleed'>
      <div className={expanded ? 'list-item-row click-target margin-1 list-item-dark' : 'list-item-row click-target margin-1'} onClick={(e) => { onClickRow(); }}>
        <div className='list-item__content'>
          <p className='contest-page__player-name'>
            {activity.player.alias} 
          </p>
          <p className='contest-page__player-subtitle'>
            {activity.wagers.length} wagers • <span className='contest-page__player-score'>{(activity.score / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})} • {overlapCount} overlaps</span>
          </p>
        </div>
        <div className='list-item__icon'>
          <FontAwesomeIcon className='icon-light' size='lg' fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <Collapse component='div' className='full-width' in={expanded}>
        <Divider className='list-divider' />
        {activity.wagers.map((wager, index) =>
        <Fragment key={wager.id}>
          <WagerListItem wager={wager} />
          {index !== activity.wagers.length - 1 &&
          <Divider className={wager.overlap ? 'list-divider__danger' : 'list-divider'} />
          }
        </Fragment>
        )}
      </Collapse>
    </ListItem>
  );
};
