import { FC } from 'react';

import Button from '@mui/material/Button';

import emptyLogo from '@app/assets/trophy-logo-light.svg';

export const EmptyContestsList: FC = () => {
  return (
    <div>
      <img src={emptyLogo} alt='No Contests' className='contests-page__empty-contests__img' />
      <p className='contests-page__empty-contests__title empty-content-title'>No Contests</p>
      <Button variant='outlined' size='large' color='primary' 
        className='button-primary' href='/contest/create' disableElevation>Create New Contest</Button>
    </div>
  );
};
