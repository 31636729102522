import { useEffect, useMemo, useRef } from "react";
import debounce from '@mui/material/utils/debounce';

export const useDebounce = (callback: (...args: any) => void, timeout?: number) => {
  const ref = useRef<any>();
  if (timeout == null) {
    timeout = 1000;
  }

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: any) => {
      ref.current?.(args);
    };

    return debounce(func, timeout);
  }, [timeout]);

  return debouncedCallback;
};
