import { PlayerModel } from '@app/data/player';
import { isSuccess } from '@app/lib/rest';
import { useQuery } from '@tanstack/react-query';

export const useFlaggedPlayers = (contestSlug?: string) => {
  return useQuery({
    enabled: contestSlug != null,
    queryKey: ['flagged-players', contestSlug],
    queryFn: async () => {
      const res = await PlayerModel.listFlaggedPlayers(contestSlug!);
      if (isSuccess(res)) {
        return res;
      }

      console.log(res.message);
      throw new Error(res.message);
    }
  });
};
