import { FlaggedPlayer } from '@app/lib/types';
import { FC } from 'react';

interface Props {
  player: FlaggedPlayer;
}

export const OtherPlayerListItem: FC<Props> = ({ player }) => {
  return (
    <div key={player.player.id} className='list-item-row indent-2'>
      <div className='list-item__content'>
        <p className='contest-page__player-name'>{player.player.alias}</p>
        <p className='contest-page__player-subtitle'>{player.similarPlays} overlapping plays</p>
      </div>
    </div>
  );
};
