import { FileUploadApi, Result } from "@app/lib/rest";
import { FileUpload } from "@app/lib/types";
import { getRequestConfig } from "./header";

export namespace FileUploadModel {
  export const create = async (contestSlug: string, upload: File): Promise<Result<FileUpload>> => {
    return FileUploadApi.create(contestSlug, upload, getRequestConfig());
  };

  export const listProcessing = async (contestSlug: string): Promise<Result<FileUpload[]>> => {
    return FileUploadApi.listProcessing(contestSlug, getRequestConfig());
  };
}
