import { createTheme } from '@mui/material';

export const auditorTheme = createTheme({
  palette: {
    primary: {
      main: '#4CB944',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#3f51b5'
    },
    error: {
      main: '#E84855'
    },
    muted: {
      main: '#F1F1F1',
      contrastText: '#6C757D',
      dark: '#E8E8E8'
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif'
    ].join(',')
  }
});
