
import { Result } from "@app/lib/rest";
import { PlayerApi } from "@app/lib/rest";
import { FlaggedPlayerGroup, Player, PlayerActivity } from "@app/lib/types";
import { getRequestConfig } from "./header";

export namespace PlayerModel {
  export const listFlaggedPlayers = async (contestSlug: string): Promise<Result<FlaggedPlayerGroup[]>> => {
    return await PlayerApi.listFlaggedPlayers(contestSlug, getRequestConfig());
  };

  export const search = async (contestSlug: string, query: string): Promise<Result<Player[]>> => {
    return await PlayerApi.search(contestSlug, query, getRequestConfig());
  };

  export const listActivity = async (contestSlug: string, players: number[]): Promise<Result<PlayerActivity[]>> => {
    return await PlayerApi.listActivity(contestSlug, players, getRequestConfig());
  };
}
