import { FC, Fragment } from 'react';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import { Responses } from '@app/lib/types';
import { ContestListItem } from './contest-list-item';

interface Props {
  contests: Responses.UserContest[];
}

export const ContestList: FC<Props> = ({ contests }) => {
  return (
    <Card variant='outlined' className='list-card'>
      {contests.map((userContest, index) =>
        <Fragment key={userContest.contest.id}>
          <ContestListItem key={userContest.contest.id} contest={userContest.contest} />
          {index !== contests.length - 1 && 
          <Divider className='list-divider' />
          }
        </Fragment>
      )}
    </Card>
  );
};
