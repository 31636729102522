import { FC } from 'react';
import { useCookies } from 'react-cookie';

import { HomePage } from '@app/pages/home';

interface Props {
  children: React.ReactNode;
}

export const RootRoute: FC<Props> = ({ children }) => {
  const [cookies] = useCookies(['auditor_token']);

  if (cookies.auditor_token != null) {
    return (
      <>{children}</>
    );
  }

  return (
    <HomePage />
  );
};
