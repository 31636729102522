import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { ErrorResponse, MSG_NETWORK_ERROR, Result } from './types';
import { Auditor, Requests } from '../types';

export namespace AuditorApi {
  export const list = async (contestSlug: string, config: AxiosRequestConfig): Promise<Result<Auditor[]>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contest/${contestSlug}/auditors`, config);
      if (res.status === 200) {
        return res.data as Auditor[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const add = async (req: Requests.AddAuditor, config: AxiosRequestConfig): Promise<Result<boolean>> => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/contest/${req.slug}/auditors`, req, config);
      if (res.status === 201) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const remove = async (req: Requests.RemoveAuditor, config: AxiosRequestConfig): Promise<Result<boolean>> => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_HOST}/contest/${req.slug}/auditors/${req.accountId}`, config);
      if (res.status === 204) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };
}
