import { FC, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Alert, TextField } from '@mui/material';
import { AccountModel, PasswordUpdateForm } from '@app/data/account';
import { isSuccess } from '@app/lib/rest';
import { useNavigate } from 'react-router-dom';

export const UpdatePasswordForm: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [currentPassword, setCurrentPassword] = useState<string>();
  const onCurrentPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(event.target.value);

  const [newPassword, setNewPassword] = useState<string>();
  const onNewPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value);

  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();
  const onConfirmNewPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(event.target.value);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(undefined);

    const body: PasswordUpdateForm = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword
    };

    const result = AccountModel.validatePasswordUpdate(body);
    if (result instanceof Error) {
      setLoading(false);
      setError(result.message);
      return;
    }

    const resp = await AccountModel.updatePassword(result);
    if (isSuccess(resp)) {
      setLoading(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');

      navigate('/logout', { replace: true });
    } else {
      setLoading(false);
      setError(resp.message);
    }
  };

  return (
    <>
      <p className='contests-page__section-title'>Update Password</p>
      <p className='form-hint'>If you update your password, you will be logged out of all your sesssions.</p>
      <div className='bottom-spacer-16'>
        <form onSubmit={onSubmit}>
          <div className='spacer-16'>
            <TextField label='Current Password' fullWidth type='password' value={currentPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onCurrentPasswordChanged(e); }} />
          </div>
          <div className='spacer-16'>
            <TextField label='New Password' fullWidth type='password' value={newPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onNewPasswordChanged(e); }} />
          </div>
          <div className='spacer-16'>
            <TextField label='Confirm New Password' fullWidth type='password' value={confirmNewPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onConfirmNewPasswordChanged(e); }} />
          </div>
          {error != null &&
          <div className='alert-container spacer-16'>
            <Alert severity='error' className='alert-full'>{error}</Alert>
          </div>
          }
          <p className='form-hint'>You will be redirected to the home page.</p>
          <div className='spacer-16 account-page__form-button'>
            <LoadingButton type='submit' variant='contained' loading={loading} className='button-400' disableElevation>
              Update
            </LoadingButton>
          </div>
        </form>
      </div>
    </>
  );
};
