import { FC } from 'react';

import loadingLogo from '@app/assets/trophy-loading.svg';

interface Props {
  message?: string;
}

export const LoadingView: FC<Props> = ({ message }) => {
  return (
    <div className='loading__container'>
      <img className='loading' src={loadingLogo} alt='Loading...' />
      <p className='loading__message'>{message ?? 'Please wait, loading...'}</p>
    </div>
  );
};
