import { AccountPage } from "@app/pages/account";
import { LoginPage } from "@app/pages/login";
import { LogoutPage } from "@app/pages/logout";
import { SignupPage } from "@app/pages/signup";
import { AuthenticatedRoute } from "./authenticated-route";

export const onboardRoutes = [
  {
    path: '/signup',
    element: <SignupPage />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/logout',
    element: <LogoutPage />
  },
  {
    path: '/account',
    element: (
      <AuthenticatedRoute>
        <AccountPage />
      </AuthenticatedRoute>
    )
  }
];
