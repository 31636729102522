import { FC } from 'react';

import Container from '@mui/material/Container';

import { SiteBar } from '@app/components/site-bar';
import { EmptyContestsList } from '@app/pages/contests/empty-contests-list';
import { useContests } from '@app/hooks/contest';
import { ContestLists } from './contest-lists';
import { Button } from '@mui/material';

export const ContestsPage: FC = () => {
  const { isFetched, data: contests } = useContests();

  return (
    <div className='contests-page'>
      <SiteBar showActions={true} />
      <Container maxWidth='md'>
        <div className='contests-page__content bottom-spacer-16'>
          {isFetched && (contests == null || contests?.length === 0) &&
          <EmptyContestsList />
          }
          {isFetched && contests!= null && contests.length > 0 &&
          <>
            <div className='contests-page__list-create'>
              <Button variant='outlined' className='contests-page__top-button button-plain' color='primary' 
                  href='/contest/create' disableElevation>
                Create New Contest
              </Button>
            </div>
            <ContestLists contests={contests} />
          </>
          }
        </div>
      </Container>
    </div>
  );
};
