import { FC, useState} from 'react';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

import { Account } from '@app/lib/types';
import { LoadingButton } from '@mui/lab';
import { AccountModel, UpdateForm } from '@app/data/account';
import { isSuccess } from '@app/lib/rest';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  account?: Account;
}

export const AccountInfo: FC<Props> = ({ account }) => {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>(account?.firstName ?? '');
  const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value);

  const [lastName, setLastName] = useState<string>(account?.lastName ?? '');
  const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value);

  const [email, setEmail] = useState<string>(account?.email ?? '');
  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(undefined);
    setShowSuccessAlert(false);

    const body: UpdateForm = {
      firstName: firstName,
      lastName: lastName,
      email: email
    };

    const result = AccountModel.validateUpdate(body);
    if (result instanceof Error) {
      setError(result.message);
      setLoading(false);
      return;
    }

    const resp = await AccountModel.update(result);
    if (isSuccess(resp)) {
      queryClient.invalidateQueries({ queryKey: ['account'] });
      setLoading(false);
      setShowSuccessAlert(true);
    } else {
      setError(resp.message);
      setLoading(false);
    }
  };

  return (
    <>
      <p className='contests-page__section-title'>Account Information</p>
      <div>
        <form onSubmit={onSubmit}>
          <div className='spacer-16'>
            <TextField label='First Name' fullWidth value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onFirstNameChanged(e); }} />
          </div>
          <div className='spacer-16'>
            <TextField label='Last Name' fullWidth value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onLastNameChanged(e); }} />
          </div>
          <div className='spacer-16'>
            <TextField label='Email' type='email' fullWidth value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onEmailChanged(e); }} />
          </div>
          <div className='alert-container spacer-16'>
            {error != null &&
            <Alert severity='error' className='alert-full'>{error}</Alert>
            }
            {showSuccessAlert &&
            <Alert severity='success' className='alert-full'>Account updated successfully</Alert>
            }
          </div>
          <div className='spacer-16 account-page__form-button'>
            <LoadingButton type='submit' variant='contained' loading={loading} className='button-400' disableElevation>
              Update
            </LoadingButton>
          </div>
        </form>
      </div>
    </>
  );
};
