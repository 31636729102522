import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';

import { Requests } from '@app/lib/types';
import { AuditorModel } from '@app/data/auditor';
import { isSuccess } from '@app/lib/rest';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AddAuditorDialog: FC<Props> = ({ open, onClose }) => {
  const queryClient = useQueryClient();
  const { contestSlug } = useParams<{ contestSlug: string }>();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>();
  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const onHandleClose = () => {
    setEmail(undefined);
    setError(undefined);
    setLoading(false);

    onClose();
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);
  
    if (contestSlug == null) {
      setError('Unexpected error occurred. Please refresh the page.');
      setLoading(false);
      return;
    }

    if (email == null) {
      setError('Email is required.');
      setLoading(false);
      return;
    }

    const body: Requests.AddAuditor = {
      email: email,
      slug: contestSlug,
    };

    const resp = await AuditorModel.add(body);
    if (isSuccess(resp)) {
      queryClient.invalidateQueries({ queryKey: ['auditors', contestSlug] });
      onHandleClose();
    } else {
      setError(resp.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth='sm'>
      <form onSubmit={onSubmit}>
        <DialogTitle>Add New Auditor</DialogTitle>
        <DialogContent>
          <p className='dialog-message'>
            Enter the email address of the user to add to the contest. The email address must be associated with a user.
          </p>
          <TextField label='Email' variant='outlined' fullWidth value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onEmailChanged(e); }} />
          <div className='alert-container spacer-16'>
            {error != null &&
            <Alert severity='error' className='alert-full'>{error}</Alert>
            }
          </div>
        </DialogContent>
        <DialogActions className='dialog-actions'>
          <Button variant='outlined' disableElevation onClick={() => { onHandleClose(); }}>Cancel</Button>
          <LoadingButton type='submit' variant='contained' disableElevation loading={loading}>Add User</LoadingButton>
        </DialogActions>
        </form>
    </Dialog>
  );
};
