import { useQuery } from '@tanstack/react-query';

import { AccountModel } from '@app/data/account';
import { isSuccess } from '@app/lib/rest';

export const useAccount = () => {
  return useQuery({
    queryKey: ['account'],
    queryFn: async () => {
      const res = await AccountModel.get();
      if (isSuccess(res)) {
        return res;
      }

      throw new Error(res.message);
    }
  });
};
