import { useQuery } from '@tanstack/react-query';

import { FileUploadModel } from '@app/data/file-upload';
import { isSuccess } from '@app/lib/rest';

export const useUpload = (slug?: string) => {
  return useQuery({
    enabled: slug != null,
    queryKey: ['uploads', slug],
    queryFn: async () => {
      const res = await FileUploadModel.listProcessing(slug!);
      if (isSuccess(res)) {
        return res;
      }

      throw new Error(res.message);
    }
  })
};
