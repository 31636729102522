import { FC, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import { LoadingButton } from '@mui/lab';

import { AccountModel } from '@app/data/account';
import { isSuccess } from '@app/lib/rest';

export const AccountDangerZone: FC = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleDeleteAccount = () => {
    confirm({ description: 'Are you sure you want to delete your account? This action is not reversible.' })
      .then(async () => {
        setLoading(true);

        const result = await AccountModel.deleteAccount();
        if (isSuccess(result)) {
          navigate('/logout', { replace: true });
          setLoading(false);
        } else {
          setError(result.message);
          setLoading(false);
        }
      }).catch(() => {
      });
  };

  return (
    <>
      <p className='section-title__danger'>Danger Zone</p>
      <Card variant='outlined' className='list-card__danger bottom-spacer-16'>
        <p className='account-page__danger-title'>Delete Your Account</p>
        <p className='account-page__danger-message'>
          Deleting your account will delete all associated data. This includes any contests you have created. This action cannot be reversed.
        </p>
        {error != null &&
        <div className='alert-container spacer-16'>
          <Alert severity='error' className='alert-full'>{error}</Alert>
        </div>
        }
        <div className='account-page__danger-button'>
          <LoadingButton loading={loading} variant='contained' color='error' disableElevation 
              onClick={(e) => { handleDeleteAccount(); }}>Delete Account</LoadingButton>
        </div>
      </Card>
    </>
  );
};
