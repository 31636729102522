import { useQuery } from '@tanstack/react-query';

import { AuditorModel } from '@app/data/auditor';
import { isSuccess } from '@app/lib/rest';

export const useAuditors = (contestSlug?: string) => {
  return useQuery({
    enabled: contestSlug != null,
    queryKey: ['auditors', contestSlug],
    queryFn: async () => {
      const res = await AuditorModel.list(contestSlug!);
      if (isSuccess(res)) {
        return res;
      }

      throw new Error(res.message);
    }
  });
};
