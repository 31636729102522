import { FC, useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useDebounce } from '@app/hooks/debounce';
import { PlayerModel } from '@app/data/player';
import { Player } from '@app/lib/types';
import { isSuccess } from '@app/lib/rest';

interface Props {
  contestSlug?: string;
  players: Player[];
  onPlayersUpdated: (players: Player[]) => void;
}

export const PlayerSearchBar: FC<Props> = ({ contestSlug, players, onPlayersUpdated }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [playerResults, setPlayerResults] = useState<Player[]>([]);
  
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const onPlayersSelected = (players: Player[]) => {
    if (players.length > 2) {
      return;
    }

    setSelectedPlayers(players);
    onPlayersUpdated(players);
  };

  useEffect(() => {
    if (selectedPlayers.length !== players.length) {
      setSelectedPlayers(players);
      onPlayersUpdated(players);
    }
  }, [players, selectedPlayers.length, onPlayersUpdated]);

  const executeSearch = async () => {
    if (contestSlug == null) {
      return;
    }

    const res = await PlayerModel.search(contestSlug, searchQuery);
    if (isSuccess(res)) {
      setPlayerResults(res);
    }
  };

  const debouncedSearch = useDebounce(executeSearch, 500);
  const onQueryChanged = (query: string) => {
    setSearchQuery(query);
    debouncedSearch();
  };

  return (
    <>
      <Autocomplete freeSolo autoComplete multiple size='medium' options={playerResults}
          renderInput={(params) => (<TextField {...params} placeholder='Names' />)}
          onInputChange={(event, query) => { onQueryChanged(query) } }
          getOptionLabel={(option) => typeof option === 'string' ? option : option.alias }
          isOptionEqualToValue={(option, value) => option.id === value.id }
          value={selectedPlayers}
          onChange={(event, result) => { result != null ? onPlayersSelected(result as Player[]) : onPlayersSelected([]) }} />
    </>
  );
};
