import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

import { SiteBar } from '@app/components/site-bar';
import { AccountModel, SignupForm } from '@app/data/account';
import { isSuccess } from '@app/lib/rest';

export const SignupPage: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  // form components state management
  const [firstName, setFirstName] = useState<string>();
  const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value);

  const [lastName, setLastName] = useState<string>();
  const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value);

  const [email, setEmail] = useState<string>();
  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const [password, setPassword] = useState<string>();
  const onPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const [confirmPassword, setConfirmPassword] = useState<string>();
  const onConfirmPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);

    const body: SignupForm = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword
    };

    const result = AccountModel.validateSignup(body);
    if (result instanceof Error) {
      setError(result.message);
      setLoading(false);
      return;
    }

    const resp = await AccountModel.signup(result);
    if (isSuccess(resp)) {
      navigate('/', { replace: true });
    } else {
      setError(resp.message);
      setLoading(false);
    }
  };

  return (
    <div className='signup-page'>
      <SiteBar title='Sign Up' />
      <Container maxWidth='sm'>
        <div className='signup-page__content'>
          <div>
            <form onSubmit={onSubmit}>
              <div className='signup-page__form'>
                <div className='spacer-16'>
                  <TextField label='First Name' fullWidth value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onFirstNameChanged(e); }} />
                </div>
                <div className='spacer-16'>
                  <TextField label='Last Name' fullWidth value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onLastNameChanged(e); }} />
                </div>
                <div className='spacer-16'>
                  <TextField label='Email' fullWidth value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onEmailChanged(e); }} />
                </div>
                <div className='spacer-16'>
                  <TextField label='Password' type='password' fullWidth value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onPasswordChanged(e); }} />
                </div>
                <div className='spacer-16'>
                  <TextField label='Confirm Password' type='password' fullWidth value={confirmPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onConfirmPasswordChanged(e); }} />
                </div>
                <div className='spacer-16'>
                  {error !== undefined &&
                  <Alert severity='error' className='alert-full'>{error}</Alert>
                  }
                </div>
                <div className='spacer-16'>
                  <LoadingButton type='submit' loading={loading} variant='contained' size='large' color='primary' 
                    className='button-primary button-min-240' disableElevation>Continue</LoadingButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};
