import { FC, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';

import { VisuallyHiddenInput } from '@app/components/visually-hidden-input';
import { FileUploadModel } from '@app/data/file-upload';
import { isSuccess } from '@app/lib/rest';
import { useUpload } from '@app/hooks/file-upload';

interface Props {
  contestSlug?: string;
}

export const WagerUploader: FC<Props> = ({ contestSlug }) => {
  const { data: pendingUploads } = useUpload(contestSlug);
  const queryClient = useQueryClient();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('No file selected');

  const [file, setFile] = useState<File | null>(null);
  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (event.target.files ?? []);
    if (files.length !== 1) {
      setError('Only one file can be processed at a time.');
      return;
    }

    setFile(files[0]);
    setFileName(files[0].name);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);

    if (file == null) {
      setError('No file selected.');
      setLoading(false);
      return;
    }

    if (contestSlug == null) {
      setError('Unexpected error occurred. Please refresh the page.');
      setLoading(false);
      return;
    }

    const result = await FileUploadModel.create(contestSlug, file);
    if (isSuccess(result)) {
      setFile(null);
      setFileName('No file selected');
      setLoading(false);
      
      queryClient.invalidateQueries({ queryKey: ['uploads', contestSlug] });
    } else {
      setError(result.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <p className='contests-page__section-title'>Upload</p>
      <Card variant='outlined' className='list-card'>
        { pendingUploads != null && pendingUploads.length > 0 ?
        <div className='contest-page__pending-upload'>
          <p className='section-title'>Processing File</p>
          <p className='form-hint'>
            The file below is currently being processed. Data should be available shortly. You can refresh this page
            to check the most current status.
          </p>
          <div className='spacer-24'>
            <TextField label='Uploaded File' variant='outlined' value={pendingUploads[0].originalFilename} fullWidth disabled />
          </div>
        </div>
        :
        <>
          <p className='contest-page__upload-hint form-hint'>
            Upload a .csv file containing wagers. If the file contains data already uploaded it will be ignored.
          </p>
          <form onSubmit={onSubmit}>
            <div className='contest-page__upload-form'>
              <div className='contest-page__upload-col-1'>
                <TextField variant='outlined' size='small' value={fileName} fullWidth disabled />
              </div>
              <div className='contest-page__upload-col-2'>
                <Button component='label' variant='contained' color='muted' disableElevation>
                  <FontAwesomeIcon icon={faFileUpload } />
                  <p className='contest-page__upload-btn-label'>Attach</p>
                  <VisuallyHiddenInput id='media' name='media' type="file" onChange={(e) => { onFileSelected(e); }} />
                </Button>
              </div>
            </div>
            { error != null &&
            <div className='alert-container spacer-16'>
              <Alert severity='error' className='alert-full'>{error}</Alert>
            </div>
            }
            <div className='contest-page__upload-submit'>
              <LoadingButton type='submit' loading={loading} variant='contained' color='muted' disableElevation>
                Submit
              </LoadingButton>
            </div>
          </form>
        </>
        }
      </Card>
    </div>
  );
};
