export const MSG_NETWORK_ERROR = 'A network error occurred. Please try again.';

export interface ErrorResponse {
  message: string;
}

type RawResult<T> = T | Error;
export type Result<T> = RawResult<T>;

export function isError<T>(result: RawResult<T>): result is Error {
  return result instanceof Error;
}

export function isSuccess<T>(result: RawResult<T>): result is T {
  return !isError(result);
}
