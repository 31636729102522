import dayjs from 'dayjs';
import Cookies from 'universal-cookie';

import { Account, Requests } from '@app/lib/types';
import { AccountApi, Result, isSuccess } from '@app/lib/rest';
import { getRequestConfig } from './header';

export interface SignupForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export interface LoginForm {
  email?: string;
  password?: string;
}

export interface UpdateForm {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface PasswordUpdateForm {
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export namespace AccountModel {
  export const signup = async (body: Requests.AccountSignUp): Promise<Result<Account>> => {
    const resp = await AccountApi.signup(body);
    if (isSuccess(resp)) {
      const cookies = new Cookies();
      cookies.set('auditor_token', resp.token, { path: '/', expires: dayjs().add(2, 'week').toDate(), sameSite: 'strict' });

      return resp.account;
    }

    return resp;
  };

  export const login = async (body: Requests.AccountLogin): Promise<Result<Account>> => {
    const resp = await AccountApi.login(body);
    if (isSuccess(resp)) {
      const cookies = new Cookies();
      cookies.set('auditor_token', resp.token, { path: '/', expires: dayjs().add(2, 'week').toDate(), sameSite: 'strict' });

      return resp.account;
    }

    return resp;
  };

  export const logout = (): void => {
    const cookies = new Cookies();
    cookies.remove('auditor_token', { path: '/' });
  };

  export const get = async (): Promise<Result<Account>> => {
    return AccountApi.get(getRequestConfig());
  };

  export const update = async (body: Requests.AccountUpdate): Promise<Result<Account>> => {
    return AccountApi.update(body, getRequestConfig());
  };

  export const updatePassword = async (body: Requests.PasswordUpdate): Promise<Result<boolean>> => {
    return AccountApi.updatePassword(body, getRequestConfig());
  };

  export const deleteAccount = async (): Promise<Result<boolean>> => {
    return AccountApi.deleteAccount(getRequestConfig());
  };

  export const validateSignup = (form: SignupForm): Requests.AccountSignUp | Error => {
    if (form.firstName == null || form.firstName === '') {
      return new Error('First name is required.');
    }

    if (form.lastName == null || form.lastName === '') {
      return new Error('Last name is required.');
    }

    if (form.email == null || form.email === '') {
      return new Error('Email is required.');
    }

    if (form.password == null || form.password === '') {
      return new Error('Password is required.');
    }

    if (form.confirmPassword == null || form.confirmPassword === '') {
      return new Error('Confirm password is required.');
    }

    if (form.password !== form.confirmPassword) {
      return new Error('Passwords do not match.');
    }

    return {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      password: form.password,
      confirmPassword: form.confirmPassword
    };
  };

  export const validateLogin = (form: LoginForm): Requests.AccountLogin | Error => {
    if (form.email == null || form.email === '') {
      return new Error('Email is required.');
    }

    if (form.password == null || form.password === '') {
      return new Error('Password is required.');
    }

    return {
      email: form.email,
      password: form.password
    };
  };

  export const validateUpdate = (form: UpdateForm): Requests.AccountUpdate | Error => {
    if (form.firstName == null || form.firstName === '') {
      return new Error('First name is required.');
    }

    if (form.lastName == null || form.lastName === '') {
      return new Error('Last name is required.');
    }

    if (form.email == null || form.email === '') {
      return new Error('Email is required.');
    }

    return {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email
    };
  };

  export const validatePasswordUpdate = (form: PasswordUpdateForm): Requests.PasswordUpdate | Error => {
    if (form.currentPassword == null || form.currentPassword.trim() === '') {
      return new Error('Current password is required.');
    }

    if (form.newPassword == null || form.newPassword.trim() === '') {
      return new Error('New password is required.');
    }

    if (form.confirmNewPassword == null || form.confirmNewPassword.trim() === '') {
      return new Error('Confirm password is required.');
    }

    if (form.newPassword !== form.confirmNewPassword) {
      return new Error('Passwords do not match.');
    }

    return {
      currentPassword: form.currentPassword,
      newPassword: form.newPassword,
      confirmPassword: form.confirmNewPassword
    };
  };
}
