import { FC, Fragment } from 'react';

import Card from '@mui/material/Card';
import { Auditor } from '@app/lib/types';
import { Divider } from '@mui/material';
import { AuditorListItem } from './auditor-list-item';
import { AddAuditorItem } from './add-auditor-item';

interface Props {
  auditors: Auditor[];
  isOwner: boolean;
}

export const AuditorList: FC<Props> = ({ auditors, isOwner }) => {
  return (
    <div>
      <p className='contests-page__section-title'>Auditors</p>
      <Card variant='outlined' className='list-card'>
        {auditors.map((auditor, index) => 
          <Fragment key={auditor.account.id}>
            <AuditorListItem auditor={auditor} isOwner={isOwner} />
            {index !== auditors.length - 1 && 
            <Divider className='list-divider' />
            }
          </Fragment>
        )}
        {isOwner &&
        <>
          <Divider className='list-divider' />
          <AddAuditorItem />
        </>
        }
      </Card>
    </div>
  );
};
