import { FC } from 'react';

import trophyLogo from '@app/assets/trophy-logo.svg';

interface Props {
  title: string;
}

export const OnboardHeader: FC<Props> = ({ title }) => {
  return (
    <div className='onboard-header__content'>
      <img src={trophyLogo} className='onboard-header__logo' alt='Contest Auditor' />
      <p className='onboard-header__title tagline'>{title}</p>
    </div>
  );
};
