import { FC, Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { FlaggedPlayerGroup, Player } from '@app/lib/types';
import { Collapse, Divider } from '@mui/material';
import { OtherPlayerListItem } from './other-player-list-item';

interface Props {
  flaggedPlayer: FlaggedPlayerGroup;
  onSearchPlayers: (players: Player[]) => void;
}

export const FlaggedPlayerListItem: FC<Props> = ({ flaggedPlayer, onSearchPlayers }) => {
  const [expanded, setExpanded] = useState(false);
  const onClickRow = () => setExpanded(!expanded);

  const players = flaggedPlayer.otherPlayers.map(p => p.player).concat(flaggedPlayer.player.player);

  return (
    <ListItem className='list-item-fullbleed'>
      <div className={expanded ? 'list-item-row click-target margin-1 list-item-dark' : 'list-item-row click-target margin-1'} onClick={(e) => { onClickRow(); }}>
        <div className='list-item__content'>
          <p className='contest-page__player-name'>{flaggedPlayer.player.player.alias.split('-')[0].trim()}</p>
          <p className='contest-page__player-subtitle'>{flaggedPlayer.otherPlayers.length} other entries</p>
        </div>
        <div className='list-item__icon'>
          <FontAwesomeIcon className='icon-light' size='lg' fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <Collapse component='div' className='full-width' in={expanded}>
        <Divider className='list-divider' />
        {flaggedPlayer.otherPlayers.map((player, index) =>
        <Fragment key={player.player.id}>
          <OtherPlayerListItem player={player} />
          {index !== flaggedPlayer.otherPlayers.length - 1 &&
          <Divider className='list-divider' />
          }
        </Fragment>
        )}
        <Divider className='list-divider' />
        <ListItemButton className='list-item' onClick={() => { onSearchPlayers(players) }}>
          <p className='list-item__subtitle'>Search these players</p>
        </ListItemButton>
      </Collapse>
    </ListItem>
  );
};
