import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { ErrorResponse, MSG_NETWORK_ERROR, Result } from './types';
import { FlaggedPlayerGroup, Player, PlayerActivity } from '../types';

export namespace PlayerApi {
  export const listFlaggedPlayers = async (contestSlug: string, config: AxiosRequestConfig): Promise<Result<FlaggedPlayerGroup[]>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contest/${contestSlug}/flagged-players`, config);
      if (res.status === 200) {
        return res.data as FlaggedPlayerGroup[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const search = async (contestSlug: string, query: string, config: AxiosRequestConfig): Promise<Result<Player[]>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contest/${contestSlug}/player/search?q=${query}`, config);
      if (res.status === 200) {
        return res.data as Player[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const listActivity = async (contestSlug: string, players: number[], config: AxiosRequestConfig): Promise<Result<PlayerActivity[]>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contest/${contestSlug}/player/activity?players=${players.join(',')}`, config);
      if (res.status === 200) {
        return res.data as PlayerActivity[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };
}