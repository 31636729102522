import { FC, useState } from 'react';

import { Player, PlayerActivity } from '@app/lib/types';
import { PlayerSearchBar } from './player-search-bar';
import { PlayerModel } from '@app/data/player';
import { isSuccess } from '@app/lib/rest';
import { PlayerActivityList } from './player-activity-list';

interface Props {
  contestSlug?: string;
  players: Player[];
  onPlayersChanged: (players: Player[]) => void;
}

export const PlayerSearch: FC<Props> = ({ contestSlug, players, onPlayersChanged }) => {
  const [results, setResults] = useState<PlayerActivity[]>([]);
  const onPlayersUpdated = async (players: Player[]) => {
    if (contestSlug == null) {
      return;
    }

    if (players.length === 0) {
      setResults([]);
      onPlayersChanged([]);
      return;
    }

    onPlayersChanged(players);

    const list = await PlayerModel.listActivity(contestSlug, players.map(p => p.id));
    if (isSuccess(list)) {
      setResults(list);
    }
  };

  return (
    <>
      <p className='section-title'>Search</p>
      <p className='form-hint subtitle'>Search by player name to list contest plays. You can search multiple names at once.</p>
      <PlayerSearchBar contestSlug={contestSlug} players={players} onPlayersUpdated={onPlayersUpdated} />
      {results.length > 0 &&
      <div>
        <p className='section-title'>Players</p>
        <PlayerActivityList activities={results} />
      </div>
      }
    </>
  );
};
