import { Responses } from '@app/lib/types';
import dayjs from 'dayjs';
import { FC } from 'react';
import { ContestList } from './contest-list';

interface Props {
  contests: Responses.UserContest[];
}

export const ContestLists: FC<Props> = ({ contests }) => {
  const currentContests = contests.filter((userContest) => 
    dayjs(userContest.contest.startTime).isSame(dayjs(), 'day') && 
    (dayjs(userContest.contest.endTime).isAfter(dayjs(), 'day') || dayjs(userContest.contest.endTime).isSame(dayjs(), 'day'))
  );
  
  const upcomingContests = contests.filter((userContest) => dayjs(userContest.contest.startTime).isAfter(dayjs(), 'day'));

  const pastContests = contests.filter((userContest) => dayjs(userContest.contest.endTime).isBefore(dayjs(), 'day'))
      .sort((a, b) => dayjs(b.contest.startTime).diff(dayjs(a.contest.startTime)));

  return (
    <>
      {currentContests.length > 0 &&
      <div>
        <p className='contests-page__section-title'>Current Contests</p>
        <ContestList contests={currentContests} />
      </div>
      }
      {upcomingContests.length > 0 &&
      <div>
        <p className='contests-page__section-title'>Upcoming Contests</p>
        <ContestList contests={upcomingContests} />
      </div>
      }
      {pastContests.length > 0 &&
      <div>
        <p className='contests-page__section-title'>Past Contests</p>
        <ContestList contests={pastContests} />
      </div>
      }
    </>
  );
};
