import { FC, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';

import { Alert, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ContestModel } from '@app/data/contest';
import { isSuccess } from '@app/lib/rest';

interface Props {
  slug?: string;
}

export const ContestDangerZone: FC<Props> = ({ slug }) => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleDeleteAccount = () => {
    if (slug == null) {
      setError('Unexpected error occurred. Please refresh the page.');
      return;
    }

    confirm({ description: 'Are you sure you want to delete the contest? This action is not reversible.' })
      .then(async () => {
        setLoading(true);

        const result = await ContestModel.remove(slug);
        if (isSuccess(result)) {
          navigate('/', { replace: true });
          setLoading(false);
        } else {
          setError(result.message);
          setLoading(false);
        }
      }).catch(() => {
      });
  };

  return (
    <>
      <p className='section-title__danger'>Danger Zone</p>
      <Card variant='outlined' className='list-card__danger bottom-spacer-16'>
        <p className='account-page__danger-title'>Delete Contest</p>
        <p className='account-page__danger-message'>
          Deleting the contest will delete all data associated with it. This action cannot be reversed.
        </p>
        {error != null &&
        <div className='alert-container spacer-16'>
          <Alert severity='error' className='alert-full'>{error}</Alert>
        </div>
        }
        <div className='account-page__danger-button'>
          <LoadingButton loading={loading} variant='contained' color='error' disableElevation 
              onClick={(e) => { handleDeleteAccount(); }}>Delete Contest</LoadingButton>
        </div>
      </Card>
    </>
  );
};
