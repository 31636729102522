import { FC } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export const AuthenticatedRoute: FC<Props> = ({ children }) => {
  const location = useLocation();
  const [cookie] = useCookies(['auditor_token']);

  if (cookie.auditor_token != null) {
    return (
      <>{children}</>
    );
  }

  const url = '/login?redirect=' + encodeURIComponent(location.pathname + location.search);
  return <Navigate to={url} state={{ from: location }} />;
};
