import { FC, useState } from 'react';

import ListItemButton from '@mui/material/ListItemButton';

import { AddAuditorDialog } from './add-auditor-dialog';

export const AddAuditorItem: FC = () => {
  const [open, setOpen] = useState(false);
  
  const handleClick = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <ListItemButton className='list-item' onClick={(e) => { handleClick(); }}>
        <p className='list-item__subtitle'>Add Auditor</p>
      </ListItemButton>
      <AddAuditorDialog open={open} onClose={onClose} />
    </>
  );
};
