import { FC } from 'react';

import { Wager } from '@app/lib/types';
import { Chip } from '@mui/material';

interface Props {
  wager: Wager;
}

export const WagerListItem: FC<Props> = ({ wager }) => {
  return (
    <div key={wager.id} className={wager.overlap === true ? 'list-item-row__danger indent-2' : 'list-item-row indent-2'}>
      <div className='list-item__content'>
        <p className='contest-page__player-name'>{wager.track.toUpperCase()} Race {wager.raceNumber}</p>
        <p className='contest-page__player-subtitle'>Selection: {wager.horses}</p>
      </div>
      <div className='list-item__icon'>
        <p className={wager.winnings <= 0 ? 'contest-page__wager-winnings' : 'contest-page__wager-winnings__won'}>
          {(wager.winnings / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
        </p>
        {wager.type === 'optional' ?
        <Chip label={wager.type} color={wager.overlap ? 'error' : 'muted'} size='small' />
        :
        <div>&nbsp;</div>
        }
      </div>
    </div>
  );
};
