import { ContestPage } from "@app/pages/contest";
import { ContestSettingsPage } from "@app/pages/contest-settings";
import { CreateContestPage } from "@app/pages/create-contest";
import { AuthenticatedRoute } from "./authenticated-route";

export const contestRoutes = [
  {
    path: '/contest/create',
    element: (
      <AuthenticatedRoute>
        <CreateContestPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: '/contest/:contestSlug',
    element: (
      <AuthenticatedRoute>
        <ContestPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: '/contest/:contestSlug/settings',
    element: (
      <AuthenticatedRoute>
        <ContestSettingsPage />
      </AuthenticatedRoute>
    )
  }
];
