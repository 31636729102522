import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { Contest, Requests, Responses } from '../types';
import { ErrorResponse, MSG_NETWORK_ERROR, Result } from './types';

export namespace ContestApi {
  export const create = async (body: Requests.CreateContest, config: AxiosRequestConfig): Promise<Result<Contest>> => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/contest/create`, body, config);
      if (res.status === 201) {
        return res.data as Contest;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const update = async (slug: string, body: Requests.UpdateContest, config: AxiosRequestConfig): Promise<Result<Contest>> => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_HOST}/contest/${slug}`, body, config);
      if (res.status === 200) {
        return res.data as Contest;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const remove = async (slug: string, config: AxiosRequestConfig): Promise<Result<boolean>> => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_HOST}/contest/${slug}`, config);
      if (res.status === 204) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const list = async (config: AxiosRequestConfig): Promise<Result<Responses.UserContest[]>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contests`, config);
      if (res.status === 200) {
        return res.data as Responses.UserContest[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const get = async (slug: string, config: AxiosRequestConfig): Promise<Result<Responses.UserContest>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contest/${slug}`, config);
      if (res.status === 200) {
        return res.data as Responses.UserContest;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };
}
