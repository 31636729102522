import { FC, useState } from 'react';
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

import { SiteBar } from '@app/components/site-bar';
import { ContestModel, CreateContestForm } from '@app/data/contest';
import { isSuccess } from '@app/lib/rest';
import { useNavigate } from 'react-router-dom';

export const CreateContestPage: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [name, setName] = useState<string>();
  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    onSlugChanged(event);
  };

  const [slug, setSlug] = useState<string>('');
  const onSlugChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slug = event.target.value.toLowerCase().replace(/[^a-z0-9]+/g, '-'); 
    setSlug(slug);
  };

  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const onStartTimeAccepted = (date: Dayjs | null) => setStartTime(date);

  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const onEndTimeAccepted = (date: Dayjs | null) => setEndTime(date);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);

    const body: CreateContestForm = {
      name: name,
      slug: slug,
      startTime: startTime?.utc().toISOString() ?? undefined,
      endTime: endTime?.utc().toISOString() ?? undefined,
    };

    const result = ContestModel.validateCreate(body);
    if (result instanceof Error) {
      setError(result.message);
      setLoading(false);
      return;
    }

    const resp = await ContestModel.create(result);
    if (isSuccess(resp)) {
      navigate(`/contest/${resp.slug}`, { replace: true });
    } else {
      setError(resp.message);
      setLoading(false);
    }
  };

  return (
    <div className='create-contest-page'>
      <SiteBar title='New Contest' showActions={true} />
      <Container maxWidth='sm'>
        <div className='create-contest-page__content'>
          <form onSubmit={onSubmit}>
            <div>
              <div className='spacer-16'>
                <TextField label='Name' fullWidth value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onNameChanged(e); }} />
              </div>
              <div className='spacer-16'>
                <TextField label='URL Slug' fullWidth value={slug}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onSlugChanged(e); }}  />
                <p className='form-hint'>
                  Contest URL will be {`${process.env.REACT_APP_WEB_HOST}/contest/${slug ?? '{slug}'}`}
                </p>
              </div>
              <div className='spacer-16'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label='Start Date' className='create-contest-page__date-field' value={startTime}
                    onAccept={onStartTimeAccepted} />
                </LocalizationProvider>
              </div>
              <div className='spacer-16'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label='End Date' className='create-contest-page__date-field' value={endTime}
                    onAccept={onEndTimeAccepted} />
                </LocalizationProvider>
              </div>
              <div className='spacer-16'>
                {error != null &&
                <Alert severity='error' className='alert-full'>{error}</Alert>
                }
              </div>
              <div className='create-contest-page__form-button spacer-16'>
                <LoadingButton type='submit' loading={loading} variant='contained' size='large' color='primary' 
                  className='button-primary button-min-240' disableElevation>Create</LoadingButton>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};
