import { FC } from 'react';
import { ConfirmProvider } from 'material-ui-confirm';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { auditorTheme } from '@app/theme';
import { AuditorRouter } from '@app/router';

export const App: FC = () => {
  return (
    <ThemeProvider theme={auditorTheme}>
      <CssBaseline />
      <ConfirmProvider>
        <AuditorRouter />
      </ConfirmProvider>
    </ThemeProvider>
  );
};
