import { FC, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Snackbar from '@mui/material/Snackbar';

import { Auditor, Requests } from '@app/lib/types';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AuditorModel } from '@app/data/auditor';
import { isSuccess } from '@app/lib/rest';

interface Props {
  auditor: Auditor;
  isOwner: boolean;
}

export const AuditorListItem: FC<Props> = ({ auditor, isOwner }) => {
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const { contestSlug } = useParams<{ contestSlug: string }>();
  const [showError, setShowError] = useState<boolean>(false);

  const onHandleDelete = () => {
    confirm({ description: 'Are you sure you want to remove this auditor?' })
      .then(async () => {
        if (contestSlug == null) {
          return;
        }

        const req: Requests.RemoveAuditor = {
          slug: contestSlug,
          accountId: auditor.account.id
        };

        const result = await AuditorModel.remove(req);
        if (isSuccess(result)) {
          queryClient.invalidateQueries({ queryKey: ['auditors', contestSlug] });
        } else {
          setShowError(true);
        }
      }).catch(() => {
      });
  };

  return (
    <>
      <ListItem className='list-item-action'>
        <div className='list-item__content'>
          <p className='list-item__title'>{auditor.account.firstName} {auditor.account.lastName}</p>
          <p className='list-item__subtitle'>{auditor.isOwner ? 'Contest Creator' : 'Auditor'}</p>
        </div>
        {isOwner && !auditor.isOwner &&
        <div className='list-item__icon'>
          <IconButton onClick={() => onHandleDelete() }>
            <FontAwesomeIcon size='sm' fixedWidth icon={faXmark} />
          </IconButton>
        </div>
        }
      </ListItem>
      <Snackbar open={showError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} 
          onClose={() => { setShowError(false); }}>
        <Alert onClose={() => { setShowError(false); }} severity="error" variant="outlined" elevation={2}
            sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
          Failed to remove auditor.
        </Alert>
      </Snackbar>
    </>
  );
};
