import { FC } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { OnboardHeader } from '@app/components/onboard-header';

export const HomePage: FC = () => {
  return (
    <div className='home-page'>
      <Container maxWidth='sm'>
        <div className='home-page__content'>
          <OnboardHeader title='Contest Auditor' />
          <div>
            <div className='spacer-24'>
              <Button variant='contained' size='large' color='primary' className='button-primary button-min-240' href='/login' disableElevation>Login</Button>
            </div>
            <div className='spacer-16'>
              <Button variant='contained' size='large' color='primary' className='button-primary button-min-240' href='/signup' disableElevation>Sign Up</Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
