import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { Button, Container } from '@mui/material';

import { SiteBar } from '@app/components/site-bar';
import { useContest } from '@app/hooks/contest';
import { WagerUploader } from './wager-upload';
import { useFlaggedPlayers } from '@app/hooks/player';
import { FlaggedPlayersList } from './flagged-players';
import { PlayerSearch } from './player-search';
import { Player } from '@app/lib/types';
import { useUpload } from '@app/hooks/file-upload';

export const ContestPage: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { contestSlug } = useParams<{ contestSlug: string }>();
  const { data: userContest, isError, isLoading } = useContest(contestSlug);

  const { data: flaggedPlayers } = useFlaggedPlayers(contestSlug);

  const { data: pendingUploads } = useUpload(contestSlug);
  const [pendingInterval, setPendingInterval] = useState<NodeJS.Timeout | null>(null);

  const [players, setPlayers] = useState<Player[]>([]);
  const onSearchPlayers = (players: Player[]) => {
    setPlayers(players);
  };

  const onPlayersChanged = (players: Player[]) => {
    setPlayers(players);
  };

  useEffect(() => {
    if (pendingUploads != null && pendingUploads.length > 0) {
      if (pendingInterval == null) {
        const interval = setInterval(() => {
          queryClient.invalidateQueries({ queryKey: ['uploads', contestSlug] });
        }, 5000);

        setPendingInterval(interval);
      }
    } else {
      if (pendingInterval != null) {
        clearInterval(pendingInterval);
        setPendingInterval(null);

        queryClient.invalidateQueries({ queryKey: ['flagged-players', contestSlug] });
      }
    }
  }, [pendingUploads, contestSlug, pendingInterval, queryClient]);

  useEffect(() => {
    if (isError && !isLoading) {
      navigate('/');
    }
  }, [isError, isLoading, navigate]);

  return (
    <div className='bottom-spacer-16'>
      <SiteBar title={userContest?.contest.name ?? undefined} showActions={true} />
      <Container maxWidth='xl'>
      <div className='contest-page__content'>
        <div className='contest-page__column-wagers'>
          <PlayerSearch contestSlug={contestSlug} players={players} onPlayersChanged={onPlayersChanged} />

          <p className='section-title'>Flagged Players</p>
          <p className='form-hint subtitle'>Players with at least 25% of the same plays.</p>
          <FlaggedPlayersList flaggedPlayers={flaggedPlayers ?? []} onSearchPlayers={onSearchPlayers} />
        </div>
        <div className='contest-page__column-info'>
          <WagerUploader contestSlug={contestSlug} />
          {userContest?.isOwner &&
          <div className='contest-page__settings'>
            <Button variant='contained' fullWidth className='button-plain' color='muted' 
                href={`/contest/${contestSlug}/settings`} disableElevation>
              Contest Settings
            </Button>
          </div>
          }
        </div>
      </div>
      </Container>
    </div>
  );
};
