import { FC } from 'react';
import { useRoutes } from 'react-router-dom';

import { RootRoute } from '@app/router/root-route';
import { onboardRoutes } from '@app/router/onboard';
import { contestRoutes } from '@app/router/contest';

import { ContestsPage } from '@app/pages/contests';

export const AuditorRouter: FC = () => {
  return useRoutes([
    {
      path: '/',
      element: (
        <RootRoute>
          <ContestsPage />
        </RootRoute>
      )
    },
    ...onboardRoutes,
    ...contestRoutes
  ]);
};
