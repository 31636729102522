import { AccountModel } from '@app/data/account';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LogoutPage: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AccountModel.logout();
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <></>
  );
};
