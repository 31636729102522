import { ContestModel } from '@app/data/contest';
import { isSuccess } from '@app/lib/rest';
import { useQuery } from '@tanstack/react-query';

/**
 * Hook that pulls the list of contests for the authenticated user.
 */
export const useContests = () => {
  return useQuery({
    queryKey: ['contests'],
    queryFn: async () => {
      const res = await ContestModel.list()
      if (isSuccess(res)) {
        return res;
      }

      throw new Error(res.message);
    }
  });
};

/**
 * Hook that pulls a single contest by its slug.
 */
export const useContest = (slug?: string) => {
  return useQuery({
    enabled: slug != null,
    queryKey: ['contests', slug],
    queryFn: async () => {
      const res = await ContestModel.get(slug!);
      if (isSuccess(res)) {
        return res;
      }

      throw new Error(res.message);
    }
  });
};
