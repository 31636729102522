import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { FileUpload } from '../types';
import { ErrorResponse, MSG_NETWORK_ERROR, Result } from './types';

export namespace FileUploadApi {
  export const create = async (contestSlug: string, upload: File, config: AxiosRequestConfig): Promise<Result<FileUpload>> => {
    try {
      const formData = new FormData();
      formData.append('file', upload);

      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/contest/${contestSlug}/upload`, formData, config);
      if (res.status === 202) {
        return res.data as FileUpload;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };

  export const listProcessing = async (contestSlug: string, config: AxiosRequestConfig): Promise<Result<FileUpload[]>> => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/contest/${contestSlug}/upload`, config);
      if (res.status === 200) {
        return res.data as FileUpload[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.message);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        if (e.response.status === 404 || e.response.status === 500) {
          return new Error(MSG_NETWORK_ERROR);
        }
        
        return new Error((e.response.data as ErrorResponse).message);
      } else {
        return new Error(MSG_NETWORK_ERROR);
      }
    }
  };
}
